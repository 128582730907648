import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Moment from 'react-moment';
import { graphql, Link } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions"
import colors from "styles/colors";
import Button from "components/_ui/Button";
import Layout from "components/Layout";
import MailchimpForm from "components/MailchimpForm";

const PostHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 3em;

    img {
        width: 100%;
        max-width: 700px;
        max-height: auto;
    }
`

const PostHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const PostButton = styled(Link)`
    margin: 2em auto;
    display: block;
    text-align: center;
`

// const PostCategory = styled("div")`
//     max-width: 550px;
//     margin: 0 auto;
//     text-align: center;
//     font-weight: 600;
//     color: ${colors.grey600};

//     h5 {
//         margin-top: 0;
//         margin-bottom: 1em;
//     }
// `

const PostTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const PostBody = styled("div")`
    max-width: 550px;
    margin: 0 auto;

    .block-img {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: none !important;

        img {
            border: 1px solid ${colors.grey400};
            max-width: 850px;

            @media(max-width: ${dimensions.maxwidthTablet}px) {
                height: auto;
                width: auto;
                max-width: 100%;
            }

            margin-top: 0.5em;
            margin-bottom: 2em;
        }
    }

    h2, h3 {
        padding-top: 1em;
    }
`

const PostMetas = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    justify-content: space-between;
    font-size: 0.85em;
    color: ${colors.grey600};
`

const PostAuthor = styled("div")`
    margin: 0;
`

const PostDate = styled("div")`
    margin: 0;
`

const CodeLink = styled(Link)`
    margin-top: 3em;
    display: block;
    text-align: center;
    margin-bottom: 7em;
`

const Post = ({ post, meta }) => {
    return (
        <>
            <Helmet
                title={`${post.post_title[0].text} | Carolyn Stransky`}
                htmlAttributes={{ lang: "en" }}
                link={[
                    {
                        rel: `canonical`,
                        href: `https://workwithcarolyn.com/blog/${post._meta.uid}`
                    }
                ]}
                meta={[
                    {
                        name: `description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                    {
                        property: `og:title`,
                        content: `${post.post_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        property: `og:description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                    {
                        name: `og:image`,
                        content: `${post.post_preview_image.url}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: `${post.post_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        name: `twitter:description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                    {
                        name: `twitter:image`,
                        content: `${post.post_preview_image.url}`,
                    }
                ].concat(meta)}
            />
            <Layout>
                {/* <PostCategory>
                    {RichText.render(post.post_category)}
                </PostCategory> */}
                <PostTitle>
                    {RichText.render(post.post_title)}
                </PostTitle>
                {post.post_hero_image && (
                    <PostHeroContainer>
                        <img src={post.post_hero_image.url} alt="" />
                        <PostHeroAnnotation>
                            {RichText.render(post.post_hero_annotation)}
                        </PostHeroAnnotation>
                    </PostHeroContainer>
                )}
                <PostMetas>
                    <PostAuthor>
                        {post.post_author}
                    </PostAuthor>
                    <PostDate>
                        <Moment format="MMMM D, YYYY">{post.post_date}</Moment>
                    </PostDate>
                    {post.post_updated_date && 
                        <PostDate>
                            <i>Updated: <Moment format="MMMM D, YYYY">{post.post_updated_date}</Moment></i>
                        </PostDate>
                    }
                </PostMetas>
                {post.additional_pdf_link && post.additional_pdf_button_text &&
                    <PostButton to={`/${post.additional_pdf_link[0].text}.pdf`} target="_blank" rel="noopener noreferrer">
                        <Button>
                            { post.additional_pdf_button_text[0].text }
                        </Button>
                    </PostButton>
                }
                {post.post_pdf_link &&
                    <PostButton to={`/slides/${post.post_pdf_link[0].text}.pdf`} target="_blank" rel="noopener noreferrer">
                        <Button>
                            PDF Slides
                        </Button>
                    </PostButton>
                }
                <PostBody>
                    {RichText.render(post.post_body)}
                    <CodeLink to={"/code"}>
                        <Button className="Button--secondary">
                            See other posts and projects
                        </Button>
                    </CodeLink>
                    <MailchimpForm />
                </PostBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const postContent = data.prismic.allPosts.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Post post={postContent} meta={meta}/>
    )
}

Post.propTypes = {
    post: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query PostQuery($uid: String) {
        prismic {
            allPosts(uid: $uid) {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_hero_annotation
                        post_preview_image
                        post_date
                        post_updated_date
                        post_category
                        post_body
                        post_author
                        post_preview_description
                        post_pdf_link
                        additional_pdf_link
                        additional_pdf_button_text
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                baseOG
            }
        }
    }
`